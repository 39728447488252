import React, { FC, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { user } from "../../../services/auth.service";
import { TextInput } from "../../../common/components/text-input";
import { validate } from "../../../utils/validation";
import PasswordChecklist from "react-password-checklist";
import { Modal } from "../../../common/components/modal";

type NewPassFormProps = {};

const NewPassForm: FC<NewPassFormProps> = () => {
  const [password, setPassword] = useState("");
  const location: any = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState("");

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    setPassword(newValue);
  }

  function updatePass(e: React.FormEvent) {
    e.preventDefault();
    try {
      setLoading(true);
      validate("password", password);
      user.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: (session) => {
            setLoading(false);
            setTextModal(
              "¡Listo! Tu contraseña se ha establecido correctamente. Por favor, inicia sesión con el correo y tu nueva contraseña."
            );
            setLoadingModal2(true);
          },
          onFailure: () => {
            setLoading(false);
            setTextModal("Tu contraseña no pudo ser cambiada, verifica que cumpla con los requerimientos");
            setLoadingModal2(true);
          },
        }
      );
    } catch (err) {
      setLoading(false);
      setTextModal("Tu contraseña no pudo ser cambiada, verifica que cumpla con los requerimientos");
      setLoadingModal2(true);
    }
  }

  function clickModal() {
    if (
      textModal === "¡Listo! Tu contraseña se ha establecido correctamente. Por favor, inicia sesión con el correo y tu nueva contraseña."
    ) {
      history.replace("/auth/login");
    } else {
      setLoadingModal2(false);
    }
  }

  return (
    <>
    <Modal
        isLoading={loading}
        showLoadingAnimation={true}
        text="Procesando..."
      />
      <Modal
        isLoading={loadingModal2}
        showLoadingAnimation={false}
        text={textModal}
        onClick={() => clickModal()}
      />
    <form style={{ width: "100%" }} noValidate onSubmit={updatePass}>
      <p className="auth-guide">
        {location.state?.guideMessage }
      </p>
      <TextInput
        label="Nueva contraseña"
        fieldName="password"
        type="password"
        value={password}
        onChange={onValueChange}
      />
      <PasswordChecklist
        className="password-check-list"
        style={{ marginBottom: "8px" }}
        iconSize={8}
        rules={["capital", "specialChar", "minLength", "lowercase", "number"]}
        minLength={8}
        value={password}
        messages={{
          minLength: "8 caracteres.",
          specialChar: "Al menos un caracter especial.",
          number: "Al menos un número",
          capital: "Al menos una letra mayúscula.",
          lowercase: "Al menos una letra minúscula.",
        }}
      />
      <input
        type="submit"
        value="Actualizar contraseña"
        className="form-submit-button w-button"
      />
    </form>
    </>
  );
};

export default NewPassForm;
export { NewPassForm };
