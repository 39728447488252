import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { login, resendEmail } from '../../../services/auth.service'
import { saveData } from '../../../services/local-storage.service'
import { TextInput } from '../../../common/components/text-input';
import { validate } from '../../../utils/validation';
import { REQUIRED_PASS } from '../../../utils/messages';
import { AUTH_USERNAME_KEY, AUTH_USER_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY } from '../../../utils/constants';

type LoginFormProps = {

};

const LoginForm: FC<LoginFormProps> = (

) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const fn: any = {
    email: setEmail,
    password: setPassword,
  };

  function onValueChange (e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      setLoading(true);
      const emailTrim = email.trim();
      validate('email', emailTrim);
      validate('password', password);
      setErrors(null);
      const {
        needsNewPass,
        loginSuccess,
        session,
      } = await login(emailTrim, password);
      if (needsNewPass) {
        setLoading(false);
        history.replace('/auth/new-password', { guideMessage: REQUIRED_PASS, needsNewPass });
      }
      if (loginSuccess) {
        const token = session?.getIdToken().getJwtToken();
        const refreshToken = session?.getRefreshToken()?.getToken();
        saveData(AUTH_USERNAME_KEY, emailTrim);
        saveData(AUTH_USER_TOKEN_KEY, token);
        saveData(AUTH_REFRESH_TOKEN_KEY, refreshToken);
        history.replace('/start');
      }
    } catch (err: any) {
      console.log('Error en datos: ', err?.message)
      if (err?.message.includes('Incorrect username or password')){
        const e : any ='El usuario o la contraseña son incorrectos'
        setErrors(e);
      }
      else if (err?.message.includes('2 validation errors detected')){
        const e : any ='El campo email contiene un espacio, solo debe contener el email registrado'
        setErrors(e);
      }
      else if (err?.message.includes('User is not confirmed')){
        const e : any ='Su usuario no ha sido verificado, por favor verifiquelo a travez del enlace que mandamos a su correo'
        setErrors(e);
      }
      else if(err?.message.includes('Temporary password has expired and must be reset by an administrator')) {
        const e : any ='La contraseña temporal con la que intenta acceder ha expirado, hemos mandado un nuevo correo con una contraseña temporal a su cuenta'
        const response = await resendEmail(email.trim())
        console.log(response)
        setErrors(e);
      }
      else setErrors(err?.message);
      setLoading(false);
    }
  }

  return (
    <>
      <form
        style={{ width: '100%' }}
        noValidate
        onSubmit={handleSubmit}
      >
        <TextInput
          label="Email"
          fieldName="email"
          autoComplete="username"
          value={email}
          onChange={onValueChange}
        />
        <TextInput
          label="Contraseña"
          fieldName="password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={onValueChange}
        />
        <input
          disabled={loading}
          type="submit"
          value={loading ? 'Procesando...' : 'Iniciar sesión'}
          className="form-submit-button w-button"
        />
        <p style={{margin: '8px 0', color: 'red'}}>
          {errors}
        </p>
      </form>
      <div className="flex-spacer show-md" />
      <div className="form-link-box">
        <span style={{ fontSize: '14px', marginRight: '8px' }}>¿No tienes una cuenta?</span>
        <Link to="/auth/register" className="form-link" replace>
          Crea una aquí
        </Link>
      </div>
      <div className="form-link-box">
        <span style={{ fontSize: '14px', marginRight: '8px' }}>Olvide mi contraseña</span>
        <Link to="/auth/recover-password" className="form-link" replace>
          Recuperala aquí
        </Link>
      </div>
      <div className="form-link-box">
        <span style={{ fontSize: '14px', marginRight: '8px' }}>Deseo eliminar mi cuenta</span>
        <Link to="/auth/unsubscribe" className="form-link" replace>
          Pulsa aquí
        </Link>
      </div>
      <div className="form-link-box">
        <span style={{ fontSize: '14px', marginRight: '8px' }}>Tienes dudas?</span>
        <Link to="/auth/help" className="form-link" replace>
          Pulsa aquí
        </Link>
      </div>
    </>
  );
}

export default LoginForm;
export {
  LoginForm,
}
