import React, {FC, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {TextInput} from '../../../common/components/text-input';
import {register} from '../../../services/auth.service';
import {validate, validateCheck} from '../../../utils/validation';
import PasswordChecklist from "react-password-checklist";
import { Modal } from '../../../common/components/modal';
import { SERVER_URL } from '../../../utils/constants';

type RegisterFormProps = {};

const RegisterForm: FC<RegisterFormProps> = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const [privacy, setPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [textModal, setTextModal] = useState('');
  const history = useHistory();

  const fn: any = {
    name: setName,
    email: setEmail,
    password: setPassword,
  };

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const newValue: string = e.target.value;
    const fieldName: string = e.target.name;
    fn[fieldName](newValue);
  }

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>): void {
    setPrivacy(e.target.checked);
  }

  async function handleSubmit(e: React.FormEvent): Promise<void> {
    try {
      e.preventDefault();
      setLoading(true);
      validate('email', email);
      validate('password', password);
      validateCheck(privacy, "el aviso de privacidad");
      setErrors(null);
      const {message, data} = await register(name, email, password);

      if (data) {
        setTextModal(`¡Listo! Ya tienes una cuenta Nutrento. Por favor, verifica tu correo mediente el codigo de confirmación que mandamos al correo ${email}`)
        setLoading(false);
        setLoadingModal2(true)
      } else {
        const err: any = message && message.es;
        setErrors(err);
        setLoading(false);
      }
    } catch (err: any) {
      console.log('Error en datos: ', err?.message)
      setErrors(err?.message);
      setLoading(false);
    }
  }

  function clickModal () {
    if (textModal === 'Error al registrarse') {
      setLoadingModal2(false)
    } else{
      history.replace(`/auth/verify?mail=${email}`);
    }
  }

  return (
    <>
      <Modal isLoading={loading} showLoadingAnimation={true} text='Procesando...'/>
      <Modal 
        isLoading={loadingModal2} 
        showLoadingAnimation={false} 
        text={textModal} 
        onClick={() => clickModal()}
      />
      <form
        style={{width: '100%'}}
        noValidate
        onSubmit={handleSubmit}
      >
        <TextInput
          label="Nombre completo"
          fieldName="name"
          type="text"
          value={name}
          onChange={onValueChange}
        />
        <TextInput
          label="Email"
          fieldName="email"
          type="email"
          autoComplete="username"
          value={email}
          onChange={onValueChange}
        />
        <TextInput
          label="Contraseña"
          fieldName="password"
          type="password"
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="current-password"
          value={password}
          onChange={onValueChange}
        />
        <div style={{fontSize: '12px'}}>
          Asegúrate de cumplir con los aspectos de una contraseña segura:
        </div>
        <PasswordChecklist
          className='password-check-list'
          style={{ marginBottom: '8px' }}
          iconSize={8}
          rules={[
            "capital",
            "specialChar",
            "minLength",
            "lowercase",
            "number",
          ]}
          minLength={8}
          value={password}
          messages={{
            minLength: "8 caracteres.",
            specialChar:
              "Al menos un caracter especial.",
            number: "Al menos un número",
            capital: "Al menos una letra mayúscula.",
            lowercase: "Al menos una letra minúscula.",
          }}
        />
        <div className="privacy-box">
          <input type="checkbox"
           id="privacy" 
           name="privacy" 
           className='input-privacy' 
           onChange={handleCheck}
          />
          <label htmlFor="privacy" className='label-privacy'>He leído y acepto el </label>
          <a href={`${SERVER_URL}/aviso-de-privacidad`} target="_blank" rel="noreferrer" className="form-link">
            Aviso de Privacidad
          </a>
        </div>
        <input
          disabled={loading}
          type="submit"
          value={loading ? 'Procesando...' : 'Registrarse'}
          className="form-submit-button w-button"
        />
        <p style={{margin: '8px 0', color: 'red'}}>
          {errors}
        </p>
      </form>
      <div className="flex-spacer show-md"/>
      <div className="form-link-box">
        <Link to="/auth/login" className="form-link" replace>
          Regresar a inicio de sesión
        </Link>
      </div>
    </>
  );
}

export default RegisterForm;
export {
  RegisterForm,
};
