// Servicio para autenticar al usuario
import { CognitoUserPool, ICognitoUserPoolData, CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoRefreshToken } from 'amazon-cognito-identity-js'
import { removeData, getData, saveData } from './local-storage.service';
import { AUTH_USERNAME_KEY, AUTH_USER_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY, API_URL, USER_KEY, SCHOOLS_KEY } from '../utils/constants'
import { SURVEYS_KEY } from '../utils/constants';

interface AuthResult {
  needsNewPass?: boolean;
  loginSuccess?: boolean;
  session?: CognitoUserSession;
  context?: any;
}

interface RegisterResult {
  message?: {
    es: string,
    default: string
  };
  data?: Object;
}

interface LoginResult {
  token: string
}

interface VerifyResult {
  result: string
}

const poolData: ICognitoUserPoolData = {
  UserPoolId: 'us-east-1_XXHK2G7pd',
  ClientId: '2c5jemp612rhdv6a37onhlhgdt',
}

let user: CognitoUser;

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

const login = async (email: string, password: string): Promise<AuthResult> => {
  
  user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  const auth = (): Promise<AuthResult> => new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: (session: any) => { 
        console.log('Session: ', session);
        resolve({ loginSuccess: true, session }); 
      },
      onFailure: (err: any) => { reject(err); },
      newPasswordRequired: (context: any) => { resolve({ needsNewPass: true, context }); },
    });
  });

  return await auth();
}

const loginApi = async (email: string, password: string): Promise<string | undefined> => {
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email, password})
  };
  let URL = `${API_URL}/users-management/login`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: LoginResult = await fetchResponse.json();
  if (!response) return undefined
  return response.token;
}

const register = async (name: string, email: string, password: string): Promise<RegisterResult> => {
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({name, email, password})
  };
  
  let URL = `${API_URL}/users-management`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: RegisterResult = await fetchResponse.json();
  
  return response;
}

const logout = (): void => {
  removeData(AUTH_USERNAME_KEY);
  removeData(AUTH_USER_TOKEN_KEY);
  removeData(AUTH_REFRESH_TOKEN_KEY);
  removeData(USER_KEY);
  removeData(SCHOOLS_KEY);
  removeData(SURVEYS_KEY);
}

const refreshCognitoSession = async (): Promise<any> => {
  const username = getData(AUTH_USERNAME_KEY);
  const refreshToken = getData(AUTH_REFRESH_TOKEN_KEY);
  
  if (!refreshToken || !username) {
    logout();
    throw new Error('No se pudo actualizar la sesión');
  }

  user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const refresh = (): Promise<any> => new Promise((resolve, reject) => {
    user.refreshSession(
      new CognitoRefreshToken({ RefreshToken: refreshToken }),
      (err: any, session: CognitoUserSession) => {
        if (err) reject(err);

        const token = session?.getIdToken().getJwtToken();
        const refreshToken = session?.getRefreshToken()?.getToken();
        saveData(AUTH_USER_TOKEN_KEY, token);
        saveData(AUTH_REFRESH_TOKEN_KEY, refreshToken);
        resolve(true);
      }
    );
  });

  return await refresh();
}

const resetPassword = async (username: string): Promise<any> => {
  user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: function(result) {
        console.log(result)
        resolve(true);
      },
      onFailure: function(err) {
        reject(err);
      }
    });
  });
}

const confirmPassword = async (username: string, verificationCode: string, newPassword: string): Promise<any> => {
  user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.confirmPassword(verificationCode, newPassword, {
      onFailure(err) {
        reject(err);
      },
      onSuccess() {
        resolve(true);
      },
    });
  });
}

const verifyCode = async (email: string, code: string): Promise<any> => {
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email, code})
  };
  let URL = `${API_URL}/users-management/verify`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: VerifyResult = await fetchResponse.json();
  if (response?.result) return response.result
  throw response;
}

const resendEmail = async (email: string): Promise<any | undefined> => {
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email})
  };
  let URL = `${API_URL}/users-management/resend-password-email`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse.json();
  if (!response) return undefined
  return response;
}

export {
  login,
  loginApi,
  logout,
  register,
  refreshCognitoSession,
  user,
  resetPassword,
  confirmPassword,
  verifyCode,
  resendEmail
}