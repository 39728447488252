import { useEffect } from 'react';
import { translateError } from '../../utils/handle-error';
import { PageHeader } from '../../common/components/main.layout';
import { selectSlice } from '../../store/slices/schools';
import { useAppSelector } from '../../store/hooks';
import useEvaluations from '../../common/hooks/use-evaluations';
import EvaluationCard from './widgets/evaluation.card';
import EvaluationsEmpty from './widgets/evaluations-empty.card';

const EvaluationsScreen = () => {
  const selectedSchool = useAppSelector(selectSlice);
  const school = selectedSchool.selected;
  const [loadEvaluations, loading, error, evaluations] = useEvaluations();

  useEffect(() => {
    loadEvaluations();
    if (error) {
      if (error instanceof Error) {
        if(error.message === 'Network error' || 'No hay conexión a internet.'){
          alert('No hay conexión a internet.');
        }
      }
      else {
        translateError(error);
        alert(error);
      }
    }
  }, [loadEvaluations, error]);

  return (
    <>
      <PageHeader screenTitle="Evaluaciones" />
      {!loading && (
        <div className="screen-container md-wide">
          {school ? <h4 style={{marginBottom:'12px', fontWeight: 'bold'}}>Los resultados de tu escuela {school.name} sobre el nivel de acciones que se realizan para fomentar ambientes alimentarios saludables son:</h4> : null}
          <br />
          {
            loading
              ? <p>Cargando datos...</p>
              : ( evaluations?.length === 0
                  ? <EvaluationsEmpty />
                  :  evaluations?.map(ev => (
                    <EvaluationCard key={ev._id} {...ev} id={ev._id}/>
                  ))
              )
          }
        </div>
      )}
    </>
  );
};

export default EvaluationsScreen;
