import React, { FC, useEffect, useState } from 'react';
import { Answer } from '../../../utils/models';
import moment from 'moment';
import { AnalyticsBarChart } from '../../../common/components/bar-chart';
import { toNumber } from '../../../utils/formatter';
import RecomendationCard from './recomendation-card';
import { getRecomendations } from '../../../services/recomendation.service';
import { Collapsible } from '../../../common/components/collapsible';

interface EvaluationCardProps extends Answer {
  id:string
}

const EvaluationCard: FC<EvaluationCardProps> = ({
  scores,
  completedAt,
  id
}) => {
  const [recomendations, setRecomendations] = useState<Array<any> | undefined>([]);
  const [isOpen, setIsOpen] = useState(false)

  async function loadRecomendations (scores: any) {
    const values:any = []
    Object.keys(scores).forEach(key => {
      if (!['DX_', 'RS_', 'PS_', 'GLOBAL'].some(v => key.includes(v))){
        values.push({
          name: key,
          value: scores[key],
        });
      }   
    })
    const res = await getRecomendations(values)
    setRecomendations(res);
  }

  useEffect(() => {
    loadRecomendations(scores);
  }, [scores]);

  const options = {
    CR_SCORE: {
      id: '1',
      display: "Organización con instituciones"
    },
    FN_SCORE: {
      id: '2',
      display: "Gestión de recursos financieros"
    },
    EAN_SCORE: {
      id: '3',
      display: "Implementación y acciones para la Educación Alimentaria Nutricional"
    },
    IN_SCORE: {
      id: '4',
      display: "Implementación y acciones asociados a la infraestructura"
    },
    LAS_SCORE: {
      id: '5',
      display: "Aplicación de normativas que ayudan a cuidar la salud nutricional de los niños y las niñas"
    },
    VI_SCORE: {
      id: '6',
      display: "Vigilancia de normativas  que ayudan a cuidar la salud nutricional de los niños y las niñas"
    },
    PAE_SCORE: {
      id: '7',
      display: "Implementación de programas de alimentación escolar"
    }  
  }

  function PrintElem(elem:string, title: string) {
    const mywindow = window.open('', 'PRINT',  "_blank");
    const html = document.getElementById(elem);

    mywindow?.document.write('<html><head><title>' + title + '</title>');
    mywindow?.document.write('</head><body >');
    mywindow?.document.write('<h1>' + title + '</h1>');
    mywindow?.document.write(html ? html.innerHTML: '');
    mywindow?.document.write('</body></html>');
    mywindow?.document.close();
    mywindow?.focus();
    mywindow?.print();
    mywindow?.close();

    return true;
  }

  return (
    <Collapsible setIsOpen={setIsOpen} isOpen={isOpen} initialHeight={48}>
    <div className="school-card">
      <div style={{display: 'flex', justifyContent:'space-between', background: '#6D31AC', position:'absolute', width: '100%', top: 0, left: 0, padding: '14px 16px 2px 16px', color: 'white'}}>
        <p>Evaluación {moment(completedAt).format('DD/MM/YY')}</p>
        {isOpen ? <p>Ocultar</p> : <p>Mostrar detalle</p>}
      </div>
      <br />
      <h4 style={{marginBottom:'18px', marginTop: '32px', fontSize: '16px'}}>Nivel de implementación de acciones y elementos de los ambientes alimentarios escolares</h4>
      <div className='container-chart'>
        <div style={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#6D31AC',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          padding: '8px',
        }}>
          <p style={{ fontSize: '12px', lineHeight: 1, color: 'white' }}>Porcentaje final: {toNumber(scores.GLOBAL_SCORE)+'%' || 0}</p>
        </div>
        <div className='container-chart-data'>
          <p style={{ fontSize: '16px', lineHeight: 1.5, marginBottom: '8px', fontWeight: 700, color: '#6D31AC' }}>{'Acciones y elementos que fomentan ambientes alimentarios escolares saludables'}</p>
          <table>
          <tbody>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 21v-13l9 -4l9 4v13"></path>
                  <path d="M13 13h4v8h-10v-6h6"></path>
                  <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.CR_SCORE.display}`}</p></td>
              <td style={{textAlign: 'right'}}><p className='table-text'>{ `${scores.CR_SCORE}%`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16 6m-5 0a5 3 0 1 0 10 0a5 3 0 1 0 -10 0"></path>
                  <path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path>
                  <path d="M5 15v1m0 -8v1"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.FN_SCORE.display}`}</p></td>
              <td style={{textAlign: 'right'}}><p className='table-text'>{ `${scores.FN_SCORE}%`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
                  <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
                  <path d="M3 6l0 13"></path>
                  <path d="M12 6l0 13"></path>
                  <path d="M21 6l0 13"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.EAN_SCORE.display}`}</p></td>
              <td style={{textAlign: 'right'}}><p className='table-text'>{ `${scores.EAN_SCORE}%`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 21l18 0"></path>
                  <path d="M9 8l1 0"></path>
                  <path d="M9 12l1 0"></path>
                  <path d="M9 16l1 0"></path>
                  <path d="M14 8l1 0"></path>
                  <path d="M14 12l1 0"></path>
                  <path d="M14 16l1 0"></path>
                  <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.IN_SCORE.display}`}</p></td>
              <td style={{textAlign: 'right'}}><p className='table-text'>{ `${scores.IN_SCORE}%`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                  <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
                  <path d="M6 14m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                  <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.LAS_SCORE.display}`}</p></td>
              <td style={{textAlign: 'right'}}><p className='table-text'>{ `${scores.LAS_SCORE}%`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M14.473 17.659a8.897 8.897 0 0 1 -2.473 .341c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                  <path d="M19 16v3"></path>
                  <path d="M19 22v.01"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.VI_SCORE.display}`}</p></td>
              <td style={{textAlign: 'right'}}><p className='table-text'>{ `${scores.VI_SCORE}%`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 14m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                  <path d="M12 11v-6a2 2 0 0 1 2 -2h2v1a2 2 0 0 1 -2 2h-2"></path>
                  <path d="M10 10.5c1.333 .667 2.667 .667 4 0"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.PAE_SCORE.display}`}</p></td>
              <td style={{textAlign: 'right'}}><p className='table-text'>{ `${scores.PAE_SCORE}%`}</p></td>
            </tr>
          </tbody>
        </table>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}>
          {(scores &&
            <AnalyticsBarChart
              value={scores.GLOBAL_SCORE || '-'}
              segments={scores}
              displayOptions={options}
            />
          )}
        </div>
      </div>
      {recomendations 
      ? <div style={{display:'flex', justifyContent:'space-between', padding:'12px', alignItems: 'center'}}>
          <h4 style={{marginBottom:'12px', fontSize: '16px'}}>Recomendaciones de Nutrento</h4>
          <button className="primary-button" style={{height: '42px'}} onClick={()  => PrintElem(id, `Recomendaciones`)}>Imprimir</button>
        </div> 
      : null }
      <div>
        
        <div id={id}>
          {
            recomendations && recomendations?.map(r => (
              <RecomendationCard key={r._id} {...r} />
            ))
          }
      </div>
      </div>
    </div>
    </Collapsible>
  );
}

export default EvaluationCard;